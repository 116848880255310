//v3 funcionan menu, 5 filtros, popup y sitio web

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './App.css';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Offcanvas, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-control-geocoder';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';






// Componente para AutocompleteSelect utilizando react-select
function AutocompleteSelect({ opciones, valor, setValor, placeholder }) {
    const opcionesFormateadas = opciones.map(opcion => ({ label: opcion, value: opcion }));

    const manejarCambio = (seleccion) => {
        setValor(seleccion ? seleccion.value : '');
    };

    return (
        <Select
            options={opcionesFormateadas}
            value={opcionesFormateadas.find(op => op.value === valor)}
            onChange={manejarCambio}
            placeholder={placeholder}
            isClearable
            isSearchable
            styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
        />
    );
}

function App() {
    const [map, setMap] = useState(null);
    const [datos, setDatos] = useState([]);
    const [markers, setMarkers] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [lugarSeleccionado, setLugarSeleccionado] = useState(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    // Estados para los filtros
	const [restaurantes, setRestaurantes] = useState([]); //new
    const [localidades, setLocalidades] = useState([]);
    const [subsectores, setSubsectores] = useState([]);
    const [categoriasPrecio] = useState(['Menos de 10€', '10€ - 20€', '20€ - 30€', 'Más de 30€']);
    const [categoriasPuntuacion] = useState(['Menos de 3', '3 - 4', 'Más de 4']);

    const [filtroRestaurante, setFiltroRestaurante] = useState('');
    const [filtroLocalidad, setFiltroLocalidad] = useState('');
    const [filtroSubsector, setFiltroSubsector] = useState('');
    const [filtroPrecio, setFiltroPrecio] = useState('');
    const [filtroPuntuacion, setFiltroPuntuacion] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);
    const handleShow = (lugar) => {
        setLugarSeleccionado(lugar);
        setShowModal(true);
    };
    const handleCloseOffcanvas = () => setShowOffcanvas(false);



    //scroll

        // Función para manejar el evento de desplazamiento (scroll horizontal)
        const handleScroll = (event) => {
            if (event.deltaX < -30 && !showOffcanvas) {
                // Deslizar hacia la izquierda para abrir el panel
                setShowOffcanvas(true);
            } else if (event.deltaX > 30 && showOffcanvas) {
                // Deslizar hacia la derecha para cerrar el panel
                setShowOffcanvas(false);
            }
        };

    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const apiKey = process.env.REACT_APP_STADIA_API_KEY;
        //console.log('API Key:', apiKey);

        const newMap = L.map(mapContainer).setView([40.416775, -3.703790], 6);
        L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${apiKey}`, {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);
		
		    // Inicializar el proveedor y el control de búsqueda
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        autoClose: true,
        retainZoomLevel: false,
        keepResult: true,
		animateZoom: true,
        showMarker: false, // No mostrar el marcador
        showPopup: false,  // No mostrar el popup con "Mark"
    });

    // Agregar el control de búsqueda al mapa
    newMap.addControl(searchControl);
	
        setMap(newMap);
        setMarkers(L.markerClusterGroup().addTo(newMap));
    }, []);

    const iconoPersonalizado = new L.Icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    const [selectedFile, setSelectedFile] = useState('');
    const archivosPredefinidos = [
        { nombre: "Restaurantes BCN", ruta: "/archivos/Restaurantes BCN.xlsx" },
        { nombre: "Restaurantes CAT", ruta: "/archivos/Restaurantes CAT.xlsx" },
        { nombre: "Restaurantes ES vNoviembre", ruta: "/archivos/Restaurantes ES vNoviembre.xlsx" }
      ];

    // Cargar datos desde el archivo Excel
    const cargarDatos = (archivo) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const hoja = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(hoja);
            setDatos(jsonData);

            // Extraer datos únicos
            const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

            console.log("Restaurantes:", restaurantesUnicos);
            console.log("Localidades:", localidadesUnicas);
            console.log("Subsectores:", subsectoresUnicos);
        };
        reader.readAsArrayBuffer(archivo);
    };


    const apiKey_bbdd = process.env.BBDD_KEY;

    // Función para cargar datos desde el PHP
    const cargarDatosDesdePHP_old = async () => {
        try {
            const response = await fetch('https://menuteca.es/backend/consultar.php?token=${apiKey_bbdd}');
            const text = await response.text();
            console.log("Respuesta cruda del servidor:", text);
            
            const jsonData = JSON.parse(text);
            setDatos(jsonData);
        } catch (error) {
            console.error("Error al cargar datos desde PHP:", error);
        }
    };
    
    // Función para cargar datos desde el PHP
const cargarDatosDesdePHP = async () => {
    try {
        const response = await fetch('https://menuteca.es/backend/consultar.php');
        if (!response.ok) throw new Error('Error al conectar con la base de datos');
        
        const jsonData = await response.json();
        setDatos(jsonData);

        // Extraer datos únicos para los filtros
        const restaurantesUnicos = [...new Set(jsonData.map(item => item.nombre))].filter(Boolean);
        const localidadesUnicas = [...new Set(jsonData.map(item => item.localidad))].filter(Boolean);
        const subsectoresUnicos = [...new Set(jsonData.map(item => item.subsector))].filter(Boolean);

        setRestaurantes(restaurantesUnicos);
        setLocalidades(localidadesUnicas);
        setSubsectores(subsectoresUnicos);

        console.log("Datos cargados desde la base de datos");
    } catch (error) {
        console.error("Error al cargar datos desde PHP:", error);
    }
};



    const handleFileChange = (event) => {
        const archivo = event.target.files[0];
        if (archivo) cargarDatos(archivo);
    };

    const handlePredefinedFileLoad = (ruta) => {
        fetch(ruta)
            .then(response => response.arrayBuffer())
            .then(data => {
                const workbook = XLSX.read(data, { type: 'array' });
                const hoja = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(hoja);
                setDatos(jsonData);
                console.log("Datos cargados desde:", ruta);
            })
            .catch(err => console.error("Error al cargar el archivo:", err));
    };

	const filtrarDatos = (lugar) => {
		console.log("Aplicando filtros...", filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion);

		const filtrarPorPrecio = (precio) => {
			if (filtroPrecio === 'Menos de 10€') return parseFloat(precio) < 10;
			if (filtroPrecio === '10€ - 20€') return parseFloat(precio) >= 10 && parseFloat(precio) <= 20;
			if (filtroPrecio === '20€ - 30€') return parseFloat(precio) > 20 && parseFloat(precio) <= 30;
			if (filtroPrecio === 'Más de 30€') return parseFloat(precio) > 30;
			return true;
		};

		const filtrarPorPuntuacion = (puntuacion) => {
			if (filtroPuntuacion === 'Menos de 3') return parseFloat(puntuacion) < 3;
			if (filtroPuntuacion === '3 - 4') return parseFloat(puntuacion) >= 3 && parseFloat(puntuacion) < 4;
			if (filtroPuntuacion === 'Más de 4') return parseFloat(puntuacion) >= 4;
			return true;
		};

		return (
			(filtroRestaurante === '' || lugar.nombre?.toLowerCase().includes(filtroRestaurante.toLowerCase())) &&
			(filtroLocalidad === '' || lugar.localidad?.toLowerCase().includes(filtroLocalidad.toLowerCase())) &&
			(filtroSubsector === '' || lugar.subsector?.toLowerCase().includes(filtroSubsector.toLowerCase())) &&
			filtrarPorPrecio(lugar['Precio comida 1']) &&
			filtrarPorPuntuacion(lugar['Puntuación google maps'])
		);
	};


    useEffect(() => {
        if (datos.length > 0) {
            const restaurantesUnicos = [...new Set(datos.map(item => item.nombre))].filter(Boolean);
            const localidadesUnicas = [...new Set(datos.map(item => item.localidad))].filter(Boolean);
            const subsectoresUnicos = [...new Set(datos.map(item => item.subsector))].filter(Boolean);
    
            setRestaurantes(restaurantesUnicos);
            setLocalidades(localidadesUnicas);
            setSubsectores(subsectoresUnicos);
        }
    }, [datos]);
    

    // Inicializar el mapa
    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer && mapContainer._leaflet_id) return;

        const newMap = L.map(mapContainer).setView([40.416775, -3.703790], 6);
        L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
            maxZoom: 18,
            attribution: '&copy; Stadia Maps'
        }).addTo(newMap);

    setMap(newMap);
    setMarkers(L.markerClusterGroup().addTo(newMap));
}, []);

    // Mostrar marcadores en el mapa
useEffect(() => {
    if (map && markers) {
        markers.clearLayers();

        // Filtrar los datos antes de añadir los marcadores
        const datosFiltrados = datos.filter(filtrarDatos);

        datosFiltrados.forEach((lugar) => {
            const latitud = parseFloat(lugar.latitud);
            const longitud = parseFloat(lugar.longitud);
            if (!isNaN(latitud) && !isNaN(longitud)) {
                const marker = L.marker([latitud, longitud], { icon: iconoPersonalizado })
                    .on('click', () => handleShow(lugar));
                markers.addLayer(marker);
            }
        });
    }
}, [datos, map, markers, filtroRestaurante, filtroLocalidad, filtroSubsector, filtroPrecio, filtroPuntuacion]);



	
    return (
        <>
            <Button variant="primary" onClick={handleShowOffcanvas} style={{ margin: '20px' }}>Abrir Filtros</Button>
                        {/* Cabecera con los logos */}
                        <div className="header-container">
                <img src={`/archivos/logo1.png`} alt="Logo 1" className="logo" />
                <img src={`/archivos/logo2.png`} alt="Logo 2" className="logo" />
            </div>

            <Button variant="secondary" onClick={cargarDatosDesdePHP} style={{ marginTop: '10px' }}>
                Cargar datos desde la base de datos
            </Button>


            
            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filtros</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/* Selección de archivo predefinido */}
                    <Form.Select onChange={(e) => handlePredefinedFileLoad(e.target.value)}>
                        <option value="">-- Seleccionar archivo predefinido --</option>
                        {archivosPredefinidos.map((archivo, index) => (
                            <option key={index} value={archivo.ruta}>{archivo.nombre}</option>
                        ))}
                    </Form.Select>
                    <hr />
                    {/* Opción de cargar archivo personalizado */}
                    <Form.Control type="file" onChange={handleFileChange} />
                    <AutocompleteSelect opciones={restaurantes} valor={filtroRestaurante} setValor={setFiltroRestaurante} placeholder="Restaurante" />
                    <AutocompleteSelect opciones={localidades} valor={filtroLocalidad} setValor={setFiltroLocalidad} placeholder="Localidad" />
                    <AutocompleteSelect opciones={subsectores} valor={filtroSubsector} setValor={setFiltroSubsector} placeholder="Subsector" />
                    <AutocompleteSelect opciones={categoriasPrecio} valor={filtroPrecio} setValor={setFiltroPrecio} placeholder="Precio" />
                    <AutocompleteSelect opciones={categoriasPuntuacion} valor={filtroPuntuacion} setValor={setFiltroPuntuacion} placeholder="Puntuación" />
                </Offcanvas.Body>
            </Offcanvas>
            <div id="map" style={{ height: '80vh' }}></div>

            {/* Modal para mostrar la información del punto */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{lugarSeleccionado?.nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Dirección:</strong> {lugarSeleccionado?.DIRECCION || 'No disponible'}</p>
                    <p><strong>CP:</strong> {lugarSeleccionado?.CP || 'No disponible'}</p>
                    <p><strong>Localidad:</strong> {lugarSeleccionado?.localidad || 'No disponible'}</p>
                    <p><strong>Subsector:</strong> {lugarSeleccionado?.subsector || 'No disponible'}</p>
                    <p><strong>Precio menú:</strong> {lugarSeleccionado?.['Precio comida 1'] || 'No disponible'}</p>
                    <p><strong>Puntuación Google Maps:</strong> {lugarSeleccionado?.['Puntuación google maps'] || 'No disponible'}</p>
                    <p><strong>Teléfono:</strong> {lugarSeleccionado?.TELEFONO1 || 'No disponible'}</p>
                    <p>
                        <strong>Sitio Web: </strong> 
                        {lugarSeleccionado?.SITIO_WEB ? (
                            <a href={lugarSeleccionado.SITIO_WEB} target="_blank" rel="noopener noreferrer">
                                {lugarSeleccionado.SITIO_WEB}
                            </a>
                        ) : 'No disponible'}
                    </p>
                    <div>
                        {lugarSeleccionado?.['imagen 1'] && (
                            <img src={lugarSeleccionado['imagen 1']} alt="Imagen 1" style={{ width: '100%', marginBottom: '10px' }} />
                        )}
                        {lugarSeleccionado?.['imagen 2'] && (
                            <img src={lugarSeleccionado['imagen 2']} alt="Imagen 2" style={{ width: '100%' }} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default App;